import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import GenericNotFound from '../Hoc/GenericNotFound';
import { default as Loader, default as LoaderMain } from '../Hoc/Loader';

const AboutUs = lazy(() => import('../pages/AboutUs/AboutUsPageLoader'));
const ContactUs = lazy(() => import('../pages/ContactUs/ContactUsPageLoader'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const ForgotPassword = lazy(() =>
  import('../pages/ForgotPassword/ForgotPassword'),
);
const Landing = lazy(() => import('../pages/home/HomePageLoader'));
const Login = lazy(() => import('../pages/Login/LoginPageLoader'));
const Privacy = lazy(() =>
  import('../pages/PrivacyPolicy/PrivacyPolicyPageLoader'),
);
const QuizScreen = lazy(() =>
  import('../pages/Dashboard/Components/SavingsView/QuizScreen'),
);
const UsersReport = lazy(() =>
  import('../pages/Dashboard/Components/UsersView/UsersReport'),
);
const SavingsDetails = lazy(() =>
  import('../pages/Dashboard/Components/SavingsView/SavingsDetails'),
);

const Register = lazy(() => import('../pages/Register/RegisterPageLoader'));
const Terms = lazy(() => import('../pages/Terms/TermsPageLoader'));

export default function RoutesMain() {
  const navigate = useNavigate();
  const location = useLocation();
  //==================== Setting state ============================
  const [stateName, setStateMethod] = useState({
    loggedin: false,
  });
  //==================== Setting state ============================
  //==================== Use Effect ===============================
  useEffect(async () => {
    const user = JSON.parse(localStorage.getItem('userData'));
    const jwt = user?.jwt;
    const userdata = user?.decoded;
    if (
      location.pathname === '/dashboard' &&
      userdata &&
      Date.now() / 1000 > userdata?.exp
    ) {
      localStorage.removeItem('userData');
      navigate('/login');
    } else if (
      location.pathname === '/dashboard' &&
      typeof userdata === 'undefined'
    ) {
      navigate('/login');
    } else if (
      (location.pathname === '/login' || location.pathname === '/register') &&
      typeof userdata !== 'undefined' &&
      Date.now() / 1000 < userdata?.exp
    ) {
      navigate('/dashboard');
      setStateMethod({
        ...stateName,
        loggedin: true,
      });
    }
  }, []);
  //==================== Use Effect ===============================
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route exact path='/' element={<Landing />} />
        <Route path='/loader' exact element={<LoaderMain />} />
        <Route path='/login' exact element={<Login />} />
        <Route path='/register' exact element={<Register />} />
        <Route path='/contact-us' exact element={<ContactUs />} />
        <Route path='/about-us' exact element={<AboutUs />} />
        <Route path='/terms-and-conditions' exact element={<Terms />} />
        <Route path='/privacy-policy' exact element={<Privacy />} />
        <Route path='/forgot-password' exact element={<ForgotPassword />} />
        <Route path='/test/test_id=:id' exact element={<QuizScreen />} />
        <Route path='/report_for/:id' exact element={<UsersReport />} />
        <Route path='/details_for/:id' exact element={<SavingsDetails />} />
        <Route path='/dashboard/#users' exact element={<Dashboard />} />
        <Route path='/dashboard/#quiz' exact element={<Dashboard />} />
        <Route path='/dashboard' exact element={<Dashboard />} />
        <Route path='*' element={<GenericNotFound />} />
      </Routes>
    </Suspense>
  );
}
