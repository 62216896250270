import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import 'core-js/stable';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import './assets/scss/index.scss';
import RoutesMain from './routes/index';
const App = () => {
  const theme = createTheme({});
  return (
    <>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <Router>
            <RoutesMain />
          </Router>
        </StyledEngineProvider>
      </ThemeProvider>
    </>
  );
};
export default App;
