import React from 'react';
import Loader from 'react-loader-spinner';

import { makeStyles } from '@mui/styles';

export default function LoaderC({ back }) {
  const useStyles = makeStyles(() => ({
    loading__spinner__main: {
      height: back ? 'auto' : '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: '0.6',
    },
  }));
  const classes = useStyles();
  return (
    <>
      <div className={classes.loading__spinner__main}>
        <div className='spinner'>
          <Loader type='ThreeDots' color='#0078ab' />
        </div>
      </div>
    </>
  );
}
