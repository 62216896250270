import React from 'react';
import { Link } from 'react-router-dom';

import { Grid, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import NotFound from '../images/notFound.png';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  d__center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  heading: {
    fontSize: '48px',
    lineHeight: '67px',
    fontWeight: 'bold',
    color: '#29264A',
    paddingBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '36px',
      color: '#29264A',
    },
  },

  para: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: '500',
    color: '#616B9C',
    paddingBottom: '1rem',
    textAlign: 'center',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '22px',
      color: '#696F8D',
      fontWeight: 'normal',
      width: '90%',
    },
  },
  gird__map__img: {
    width: '25rem',
  },
  all__sec__btn: {
    background: '#0078ab',
    boxShadow: 'none',
    height: '44px',
    width: '130px',
    marginTop: '1rem',
    color: 'white',
    fontWeight: 'bold',
    marginTop: '2rem',
    borderRadius: '10px',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    outline: 'none',
    '&:hover': {
      background: '#0078ab',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
}));

export default function Loader() {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.d__center}>
          <Typography className={classes.heading}>
            Sorry, page not found!
          </Typography>
          <Typography className={classes.para}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>
          <img
            src={NotFound}
            alt='BigCo Inc. logo'
            className={classes.gird__map__img}
          />
          <Link to='/' className={classes.all__sec__btn}>
            Go to Home
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
